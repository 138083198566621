<template>
  <label :for="id">{{ label }}</label>
  <input
    :id="id"
    :value="modelValue"
    :type="type"
    size="8"
    @input="$emit('update:modelValue', $event.target.value)"
  />
  <p class="error" v-if="hasError">Yikes! That's not right. Try again?</p>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: [String, Number],
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
label,
input {
  display: block;
}

.error {
  color: orangered;
  font-weight: bold;
}

input {
  padding: 0.25rem;
  border-radius: 6px;
}
</style>