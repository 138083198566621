<template>
  <div v-if="!showResume" class="wrapper">
    <Lock class="lock">
      <form @submit.prevent="checkPass(lolPass)" class="lock__form">
        <BaseInput
          id="pass1"
          label="Password"
          v-model="lolPass"
          :hasError="inputHasError"
        />
        <BaseButton label="Submit" />
      </form>
    </Lock>
    <div class="hint">
      <BaseButton
        v-if="!showHint"
        @click="showHint = !showHint"
        class="hint__button"
        label="Need a hint?"
        type="button"
      />
      <p v-else>
        <Emoji>🤫</Emoji>
        Use <code>Command+Option+J</code>, or <code>Control+Shift+J</code> on
        Windows.
      </p>
    </div>
  </div>

  <Resume v-if="showResume" />
</template>

<script>
import { ref } from "vue";

import BaseButton from "./components/BaseButton.vue";
import BaseInput from "./components/BaseInput.vue";
import Resume from "./components/Resume.vue";
import Lock from "./components/Lock.vue";
import Emoji from "./components/Emoji.vue";

export default {
  components: {
    BaseButton,
    BaseInput,
    Resume,
    Lock,
    Emoji,
  },
  setup() {
    const lolPass = ref("");
    const showHint = ref(false);
    const showResume = ref(false);
    const inputHasError = ref(false);

    const checkPass = (pass) => {
      if (pass === "asdf") {
        showResume.value = true;
        inputHasError.value = false;
      } else {
        showResume.value = false;
        inputHasError.value = true;
      }
    };

    const catComment = `
      /**
       * Welcome to the console log!
       * 
       * Boop! This is just a faux password checker!
       * 
       * This is NOT a real password, it's just an easter egg.
       * Or, rather, a cat. An easter cat.
       * 
       * Oh, and the input is "asdf" without quotes.
       * Tricksy, I know.
       * 
       *      |\\__/,|   ( )      
       *    _.|o o  |_   ) )     
       *  --(((---(((----------   
       **/
     `;

    console.log(catComment);

    const checkUrlForPass = () => {
      const urlParams = new URLSearchParams(window.location.search);

      if (urlParams.get("lolpass") === "asdf") {
        showResume.value = true;
      }
    };

    // If the lolpass is present in the URL on created, go ahead and unlock!
    checkUrlForPass();

    return {
      lolPass,
      inputHasError,
      showHint,
      showResume,
      checkPass,
    };
  },
};
</script>
