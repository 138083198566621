<template>
  <div class="wrapper">
    <div class="salutation">
      <Emoji class="salutation__decoration">✨💾</Emoji>
      <h1>
        <span class="salutation__name">Novella Chiechi</span>
        <span class="salutation__title">Frontend Software Engineer</span>
      </h1>
    </div>

    <div class="resume-container">
      <div class="sidebar">
        <div class="contact">
          <h2 class="contact__title">
            <Emoji>📠</Emoji>
            Contact info
          </h2>
          <ul aria-labelledby="contact__title" role="list">
            <li>
              <Emoji>💌 </Emoji>
              <a
                href="mailto:novella@altodev.net"
                subject="Resume inquiry from the internets"
                >novella@altodev.net</a
              >
            </li>
            <!-- I let my personal site lapse, too busy working on product and fun things! -->
            <!-- <li>
              <Emoji>🕸️ </Emoji>
              <a href="https://novella.dev">novella.dev</a>
            </li> -->
            <li>
              <Emoji>🐙 </Emoji>
              <a href="https://github.com/novellac">github.com/novellac</a>
            </li>
            <li>
              <Emoji>🐦 </Emoji>
              <a href="https://twitter.com/novelladev"
                >twitter.com/novelladev</a
              >
            </li>
          </ul>
        </div>

        <!-- Tech stack -->
        <div class="stack">
          <h2 id="stack__title">
            <Emoji>🥞</Emoji>
            Tech stack
          </h2>
          <h3 id="stack--styling">Style it!</h3>
          <ul aria-labelledby="stack__title stack--styling">
            <li>Bulma, Tailwind (TW and Windi)</li>
            <li>CSS (SCSS, LESS)</li>
            <li>Figma</li>
            <li>Storybook</li>
          </ul>
          <h3 id="stack--code">Code it!</h3>
          <ul aria-labelledby="stack__title stack--code">
            <li>Javascript</li>
            <li>MacOS, Linux (Ubuntu)</li>
            <li>Svelte</li>
          </ul>
          <h3 id="stack--organization">Wrangle it!</h3>
          <ul aria-labelledby="stack__title stack--organization">
            <li>Trello, Notion</li>
            <li>Git (GitHub)</li>
            <li>Netlify</li>
            <li>Vercel</li>
          </ul>
          <h3 id="stack--testing">Testing 1, 2, 3</h3>
          <ul aria-labelledby="stack__title stack--testing">
            <li>Cypress</li>
            <li>Jest (Vue Test Utils)</li>
          </ul>
          <h3 id="stack--cms">CMS</h3>
          <ul aria-labelledby="stack__title stack--cms">
            <li>Drupal 7, Drupal 8</li>
            <li>Tina</li>
            <li>Forestry</li>
            <li>Storyblok</li>
          </ul>
          <h3 id="stack--vue">Do the Vue!</h3>
          <ul aria-labelledby="stack__title stack--vue">
            <li>Vue i18n</li>
            <li>Vee-validate, Vuelidate</li>
            <li>Vuex, Pinia</li>
            <li>Vue Query</li>
            <li>Options API, Composition API</li>
            <li>Vue 2, Vue 3, Nuxt, Gridsome, VuePress</li>
          </ul>
        </div>

        <!-- Leadership and volunteer -->
        <div class="leadership">
          <h2 id="leadership__title">
            <Emoji>🌱</Emoji>
            Leadership
          </h2>
          <ul aria-labelledby="leadership__title">
            <li>Vuetroit - Detroit Vue Meetup organizer, 2018-2020</li>
            <li>
              Global Diversity Call for Proposals Day Detroit - Co-organizer,
              2019-2020
            </li>
            <li>Cherry on Tech - Frontend lead, 2020 - present</li>
          </ul>
        </div>

        <!-- Education -->
        <div class="education">
          <h2 id="education__title">
            <Emoji>🎓</Emoji>
            Education
          </h2>
          <ul role="list" aria-labelledby="education__title">
            <li>M.A. - History, University of Chicago</li>
            <li>
              B.A. - Asian Studies & Political Science, Cornell University
            </li>
          </ul>
        </div>
      </div>

      <div class="main">
        <!-- SWE profile -->
        <div class="profile">
          <h2>
            <Emoji>✨</Emoji>
            Profile
          </h2>
          <p>
            Frontend software engineer with 4+ years of experience working on a
            product, and a total of 6+ years of experience in design
            implementation and CMS integration. Continually growing into new
            technological roles. Skilled in communicating with diverse
            stakeholders in order to take vague, tangled problems and create
            strong relationships and achievable goals.
          </p>
        </div>

        <!-- Professional experience -->
        <div class="experience">
          <h2 id="experience__title">
            <Emoji>💼</Emoji>
            Professional experience
          </h2>

          <!-- VoiceThread -->
          <h3 id="experience__position--vt" class="experience__position">
            <span class="experience__position--company">VoiceThread</span>
            <span class="experience__position--role">
              Frontend Software Engineer
            </span>
            <span class="experience__position--duration">2020 - Present</span>
          </h3>
          <ul aria-labelledby="experience__position--vt">
            <li>
              Lead collaborative
              <span class="experience__position--text-bold">
                a11y (accessibility)
              </span>
              planning and testing between Product, Design, and Engineering
              teams
            </li>
            <li>
              Identify and remediate
              <span class="experience__position--text-bold"> WCAG </span>
              violations, thus increasing sales and retention of institutional
              customers
            </li>
            <li>
              Oversee hiring and
              <span class="experience__position--text-bold">mentorship</span>
              for one junior engineer
            </li>
            <li>
              Contribute to the architecture of the frontends of several
              VoiceThread products, including
              <span class="experience__position--text-bold">Vue SPAs</span>
              and an interactive video player written in
              <span class="experience__position--text-bold">Svelte</span>
              .
            </li>
            <li>
              Lead 3-month project to implement
              <span class="experience__position--text-bold"> dark mode </span>
              across 7 SPAs. Removed all Tailwind color classes from components
              and combined into reusable classes. Refactored shared component
              library to assign style variants as props in SFCs. Created TS
              variants files with variants of more complicated styling.
            </li>
            <li>
              Creation and maintenance of
              <span class="experience__position--text-bold">Storybook</span>
              components, collaboration and testing with Design team
            </li>
            <li>
              Expand and maintain the legacy marketing site with
              <span class="experience__position--text-bold">Hugo</span> and
              <span class="experience__position--text-bold">Bootstrap</span>
            </li>
            <li>
              Thorough testing with
              <span class="experience__position--text-bold">Jest</span> and
              <span class="experience__position--text-bold">
                Vue Test Utils </span
              >, and
              <span class="experience__position--text-bold">Cypress</span>
              for end-to-end testing
            </li>
            <li>
              State management with modularized
              <span class="experience__position--text-bold">Vuex</span> and
              <span class="experience__position--text-bold">Pinia</span>
            </li>
            <li>
              Edit and animate
              <span class="experience__position--text-bold">SVG</span>
              illustrations
            </li>
            <li>
              Style components with customized
              <span class="experience__position--text-bold">Tailwind CSS</span>
            </li>
            <li>
              Champion frontend
              <span class="experience__position--text-bold">documentation</span>
              efforts
            </li>
          </ul>

          <!-- Alto -->
          <h3 id="experience__position--alto" class="experience__position">
            <span class="experience__position--company">Alto LLC</span>
            <span class="experience__position--role">
              Founder, Principal Software Engineer
            </span>
            <span class="experience__position--duration">2014 - Present</span>
          </h3>
          <p>
            Founded and continued successful remote freelance business doing
            both contract and subcontracting work.
          </p>
          <ul aria-labelledby="experience__position--alto">
            <li>
              <a href="https://precisionwordcraft.com/">Precision Wordcraft</a>
              - Website based on Vue and
              <span class="experience__position--text-bold">Storyblok</span> to
              showcase the work of an editor and translator. The site features
              <span class="experience__position--text-bold">Vue i18n</span>, as
              well as the ability to maintain a default language in localstorage
              while browsing in other languages. The site also features
              paginated side-by-side translations into three languages. Client
              has approved repo views on request.
            </li>
            <li>
              <a href="http://matterport.com">Matterport</a>
              - Created
              <span class="experience__position--text-bold">Twig</span> and
              <span class="experience__position--text-bold">Pattern Lab</span>
              components, taking charge of several site features, including
              reworking legacy Drupal 8 code. Worked as part of a ~10-person
              team under intense deadlines.
            </li>
            <li>
              <a href="https://donnaelam.com/">DLC One Collective</a> - Brochure
              site for boutique health and wellness firm, using
              <span class="experience__position--text-bold">Tailwind</span>,
              <span class="experience__position--text-bold">Gridsome</span>, and
              Google Forms for forms and deployment.
            </li>
            <li>
              <a href="https://www.mangoafricansafaris.com/">
                Mango African Safaris
              </a>
              - Using
              <span class="experience__position--text-bold">Nuxt</span> and
              <span class="experience__position--text-bold">Bulma</span>, wrote
              tagging and filtering components in Javascript for list pages,
              administrative interface for individual itineraries, and basic
              Cypress tests.
            </li>
            <li>
              <a href="https://www.earley.com/">Earley Information Science</a> -
              Site architecture, theming. Upgraded and added
              <span class="experience__position--text-bold">Solr search</span>
              features. Created a Javascript slider feature for a legacy Drupal
              site, pulling from Drupal
              <span class="experience__position--text-bold">REST API</span>.
            </li>
            <li>
              Linux.com - Conducted
              <span class="experience__position--text-bold">
                manual testing and QA
              </span>
              for desktop and mobile applications during site migration.
            </li>
            <li>
              Various -
              <span class="experience__position--text-bold"
                >Site architecture</span
              >
              and
              <span class="experience__position--text-bold">theming</span> for
              Drupal 8 sites.
              <span class="experience__position--text-bold"
                >Planned migrations</span
              >
              for Drupal 6 and Drupal 7 to Drupal 8 projects. Themes written in
              <span class="experience__position--text-bold">Twig</span>,
              <span class="experience__position--text-bold">SCSS</span>, or
              <span class="experience__position--text-bold">SASS</span> using
              Bulma,
              <span class="experience__position--text-bold">Bootstrap</span>, or
              custom CSS frameworks.
            </li>
          </ul>
        </div>

        <!-- Juste pour rire -->
        <div class="fun">
          <h2 id="fun__title">
            <Emoji>🧪</Emoji>
            Code Experiments / Just for laughs (TM)
          </h2>
          <p>
            Small projects I make for fun, to practice a skill, or as part of a
            volunteer project.
          </p>
          <ul aria-labelledby="fun__title">
            <li>
              <a href="https://knuckles.dev/">Name my knuckles</a> - An app to
              generate knuckle tattoo suggestions. The SVG fists have
              customizable skin, nail, and font colours.
            </li>
            <li>
              <a href="https://cssfishtank.dev/">CSS Fish Tank</a> - A webpage
              with a peaceful underwater scene, coded entirely in HTML and CSS.
            </li>
            <li>
              <a href="https://cherryon.tech/">Cherry on Tech</a> - Website for
              Cherry on Tech, an organization which helps people from
              underrepresented genders embark on and level up in tech careers.
              As a frontend lead, my role is mostly one of mentorship and PR
              review.
            </li>
            <li>
              <a href="https://www.npmjs.com/package/stringbob">String Bob</a> -
              An NPM package which turns strings into SaRCasTiC TeXt.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Emoji from './Emoji.vue';

export default {
  components: {
    Emoji,
  },
};
</script>
