<template>
  <div class="grid-container">
    <div class="lock__top"></div>
    <div class="lock__side--1"></div>
    <div class="lock__side--2"></div>
    <div class="lock__body">
      <div class="lock__slot">
        <slot />
      </div>
    </div>
  </div>
</template>

<style scoped>
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

.grid-container {
  width: 13rem;
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 5rem 3rem 1rem auto;
}

.lock__top {
  background-color: transparent;
  grid-column-start: 2;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 2;
  border: 1.5rem solid silver;
  border-radius: 50%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-width: 0;
}

.lock__side--1 {
  background-color: silver;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  width: 1.5rem;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;
}

.lock__side--2 {
  background-color: silver;
  width: 1.5rem;
  justify-self: end;
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 2;
  grid-row-end: 4;
}

.lock__body {
  border: 0.25rem solid #c9c9c9;
  border-radius: 6px;
  grid-column-start: 1;
  grid-column-end: -1;
  grid-row-start: 4;
  grid-row-end: -1;
  display: flex;
  background: hsla(0, 0%, 79%, 1);

  background: linear-gradient(
    90deg,
    hsla(0, 0%, 79%, 1) 0%,
    hsla(9, 76%, 92%, 1) 51%,
    hsla(0, 0%, 79%, 1) 100%
  );
}
</style>